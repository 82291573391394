import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  alarms: {},
  operationAlarms: {}
};

const alarmSlice = createSlice({
  name: 'alarm',
  initialState,
  reducers: {
    setAlarms: (state, action) => {
      state.alarms = action.payload;
    },
    setOperationAlarms: (state, action) => {
      state.operationAlarms = action.payload;
    },
  
  }
});

export const { setAlarms, setOperationAlarms, clearAlarms } = alarmSlice.actions;
export default alarmSlice.reducer;