import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setLedLive } from '../services/slices/appConfig';
import { setLiveData } from '../services/slices/liveDataSlice';
import { setAlarms } from '../services/slices/amsSlice';

const useSimulationData = (processLiveData, liveFlag) => {
  const [simulationPlay, setSimulationPlay] = useState(true);
  const [simulationData, setSimulationData] = useState({});
  const [sliderValue, setSliderValue] = useState(0);
  const isSliding = useRef(false);
  const dataFlag = useRef(true);
  const loadingFlag = useRef(false);

  const dispatch = useDispatch();

  const findMatchingKey = useCallback((keys, targetTime) => {
    const targetDate = new Date(targetTime);
    const targetMinute = targetDate.getMinutes();
    const targetSecond = targetDate.getSeconds();

    for (const key of keys) {
      const date = new Date(key);
      if (date.getMinutes() === targetMinute && date.getSeconds() === targetSecond) {
        return key;
      }
    }

    return null;
  }, []);

  const getCombinedData = useCallback((keys) => {

    return keys.reduce((accumulator, key) => {
  console.log('simulationData[key]', simulationData[key])

      return { ...accumulator, ...simulationData[key] };
    }, {});
  }, [simulationData]);
  const updateData = useCallback(() => {
    const keys = Object.keys(simulationData);
    let matchingKey = findMatchingKey(keys, sliderValue);

    if (!matchingKey) {
      const previousSliderValue = new Date(sliderValue - 1000);
      matchingKey = findMatchingKey(keys, previousSliderValue);

      if (!matchingKey) {
        dispatch(setAlarms({}));
        dispatch(setLiveData({}));
        return;
      }
    }

    const matchingDate = new Date(matchingKey);
    const matchingISODate = matchingDate.toISOString().replace(/\.\d+Z$/, 'Z');
    const matchingKeys = keys.filter(key => key.startsWith(matchingISODate.slice(0, -1)));

    const combinedData = getCombinedData(matchingKeys);
    console.log('combinedData', combinedData)
    if (Object.keys(combinedData)) {
      processLiveData(combinedData);
      // dispatch(setLedLive(true));
    } else {
      // dispatch(setLedLive(false));
    }
  }, [dispatch, findMatchingKey, getCombinedData, processLiveData, simulationData, sliderValue]);

  useEffect(() => {
    if (!liveFlag.current && !isSliding.current) {
      updateData();
    }
  }, [simulationPlay, sliderValue, simulationData, updateData, liveFlag]);

  return {
    simulationPlay,
    setSimulationPlay,
    simulationData,
    setSimulationData,
    sliderValue,
    setSliderValue,
    isSliding,
    dataFlag,
    loadingFlag
  };
};

export default useSimulationData;
