import { createSlice } from '@reduxjs/toolkit';


const userSlice = createSlice({
  name: 'user',
  initialState: {
  },
  reducers: {
    clearUserData: (state) => {
      return {}; 
    }
  }
});

export const { clearUserData } = userSlice.actions;


export default userSlice.reducer;
