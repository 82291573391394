// src/store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import alarmReducer from './slices/amsSlice';
import sensorGroupReducer from './slices/sensorGroupSlice';
import renamedSensorsReducer from './slices/renamedSensorsSlice';
import tagsReducer from './slices/tagsSlice';
import sensorLimitsReducer from './slices/sensorLimitsSlice';
import liveDataReducer from './slices/liveDataSlice';
import appConfigReducer from './slices/appConfig';
import sensorMultiplierReducer from './slices/sensorMultiplierSlicer';
import reloadReducer from './slices/reloadSlice';
import shipsNameReducer from './slices/shipsNameSlicer';
import userReducer, { clearUserData } from './slices/usersSlice';

const appReducer = combineReducers({
  alarm: alarmReducer,
  sensorGroup: sensorGroupReducer,
  renamedSensors: renamedSensorsReducer,
  tags: tagsReducer,
  sensorLimits: sensorLimitsReducer,
  liveData: liveDataReducer,
  appConfig: appConfigReducer,
  sensorMultiplier: sensorMultiplierReducer,
  reload: reloadReducer,
  shipsName: shipsNameReducer,
  user: userReducer
});

const rootReducer = (state, action) => {
  if (action.type === clearUserData.type) {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer
});

export default store;
