import { useEffect, useRef, useState } from 'react';
import Configurator from '../config/Configurator.js';
import { useDispatch, useSelector } from 'react-redux';
import { setLedLive } from '../services/slices/appConfig.js';
import { clearCache } from '../api/Fetchers/fetch.old.data.js';
import { clearData } from '../Components/AddElementPanel2/utils/clear.data.js';

let wsInstance = null;

const useWebSocket = (isLoggedIn, liveFlag, processLiveData, setIsLoggedIn, isSliding) => {

    /*** * RECONNECTION ATTEMPTS * ***/

    const [reconnectAttempts, setReconnectAttempts] = useState(0);

    const dispatch = useDispatch();
    const terminate = useRef(false);
    const lastKnownRecords = useRef({});
    const reconnectTimeout = useRef(null);

    /*** * SENSORS THAT APPLICATION NEEDS * ***/

    const neededSensors = useSelector((state) => state.appConfig.neededSensors);

    /*** * AUTORIZAATION VARIABLES* ***/

    const apiKeyRef = useRef(null);
    const userIDRef = useRef(null);

    const sendMessage = (message) => {

        if (wsInstance && wsInstance.readyState === WebSocket.OPEN) {
            console.log('sending message', message);
            const authMessage = {
                ...message,
                auth: {
                    idUser: parseInt(userIDRef.current),
                    session: apiKeyRef.current
                },
            };
            wsInstance.send(JSON.stringify(authMessage));
        }
    };



    useEffect(() => {
        const initializeWebSocket = async () => {
            let wsUrl;

            const config = await Configurator();
            const apiKey = localStorage.getItem("apiKey");
            const userID = localStorage.getItem("userID");

            apiKeyRef.current = apiKey;
            userIDRef.current = userID;

            const host = new URL(config.API_URL).host;
            const wsPrefix = config.API_WS_PREFIX;
            wsUrl = `${wsPrefix}://${host}/api/v2/io/records/abstract/live`;

            if (!apiKey || !userID) {
                console.log("Missing or invalid API key or user ID");
                return;
            }

            wsInstance = new WebSocket(wsUrl);

            wsInstance.onopen = () => {
                setReconnectAttempts(prevReconnect => prevReconnect + 1);
                const firstMsg = JSON.stringify({
                    "auth": { "isUser": parseInt(userID), "session": apiKey },
                });

            };

            const missingRecordCount = {};
            wsInstance.onmessage = (event) => {
                try {
                    if (!isLoggedIn) {
                        wsInstance.close(1000, "User logged out");
                        return;
                    }

                    const data = JSON.parse(event.data);
                    if (data?.status?.errCode === 3.0) {
                        setIsLoggedIn(false);
                        localStorage.setItem("userID", '');
                        return;
                    }
                    if (data?.status?.errCode > 0) {
                        return;
                    }
                    if (data?.Metadata?.repeat) {
                        dispatch(setLedLive(false));
                        return;
                    }

                    if (liveFlag.current && !isSliding.current) {

                        //TAKE SHIP SERIALS  OBJECT WITH THE LATEST RECORDS
                        const records = data?.data?.records || {};

                        const liveData = {};
                        Object.keys(records).forEach(key => {
                            if (Object.keys(records[key].data.latestRecord).length === 0) {
                                missingRecordCount[key] += 1;

                                /// * If we have 10 continuous empty records, update lastKnownRecords * ///
                                if (missingRecordCount[key] >= 10) {
                                    // If we have 10 continuous empty records, update lastKnownRecords
                                    lastKnownRecords.current[key] = records[key].data.latestRecord;
                                    missingRecordCount[key] = 0; // Reset counter
                                    liveData[key] = false;
                                } else {
                                    records[key].data.latestRecord = lastKnownRecords.current[key] || {};
                                    liveData[key] = false;
                                }

                            } else {
                                // Update the last known value for the current record
                                liveData[key] = true
                                lastKnownRecords.current[key] = records[key].data.latestRecord;
                            }
                        });
                        dispatch(setLedLive(liveData));
                        if (Object.keys(data?.data?.records).length > 0) {
                            /*** * SET THE DATA * ***/
                            processLiveData(records);

                        } else {

                        }
                    }
                } catch (e) {
                    console.log(e);
                }
            };

            wsInstance.onclose = () => {
                if (isLoggedIn) {
                    // Attempt to reconnect after 5 seconds
                    reconnectTimeout.current = setTimeout(() => {

                        initializeWebSocket();
                    }, 5000);
                } else {
                    wsInstance.current = null;
                }

            };

            wsInstance.onerror = (error) => {
                console.log('WebSocket error', error);
                if (isLoggedIn && !terminate.current) {
                } else {
                    wsInstance = null;
                }

            };

        };

        if (isLoggedIn) {
            initializeWebSocket();
        } else if (wsInstance) {
            clearCache();
            terminate.current = true;
            processLiveData({});
            clearData(dispatch);

            wsInstance.close(1000, "User logged out");
        }

        return () => {
            terminate.current = true;
            if (wsInstance) {
                wsInstance.close(1000, "User logged out");
            }
            if (reconnectTimeout.current) {
                clearTimeout(reconnectTimeout.current);
            }
        };
    }, [isLoggedIn, liveFlag]);

    /*** * LISTEN ON CHANGES OF DESIRED SENSORS * ***/

    useEffect(() => {
        if (neededSensors === 'error') {
            setIsLoggedIn(false);

        } else if (neededSensors && Object.keys(neededSensors).length > 0 && wsInstance && wsInstance.readyState === WebSocket.OPEN) {
            sendMessage({ data: { tagsSources: neededSensors, "msIntInterval": 1000 } });
        }
    }, [neededSensors, wsInstance, reconnectAttempts]);

    return { wsInstance };


};

export default useWebSocket;