import { createSlice } from '@reduxjs/toolkit';

const sensorLimitsSlice = createSlice({
  name: 'sensorLimits',
  initialState: {},
  reducers: {
    setSensorLimits: (state, action) => {
      return action.payload;
    },
    clearSensorLimits: () => {
      return {};
    }
  }
});

export const { setSensorLimits, clearSensorLimits } = sensorLimitsSlice.actions;
export default sensorLimitsSlice.reducer;
