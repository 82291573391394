import React, { useEffect, useState } from "react";
import Image from "../../assets/bgPixel.jpg";
import { FaEye } from "react-icons/fa6";
import { FaEyeSlash } from "react-icons/fa6";
import './Login.css'
import { useNavigate } from 'react-router-dom';
import ecpIcon from '../../assets/elecicon.webp'
import ecpImg from '../../assets/ecp-img.png'
import TypeScript from "./Components/TypeScript.js";
// import SerialSelect from "./Components/SerialSelect/SerialSelect.js";
import LoginRequest from "../../api/LoginValidator/login.request.js";

const Login = React.memo(({ setIsLoggedIn }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [validEmail, setValidEmail] = useState(true);

    const navigate = useNavigate();


    const validateEmail = (input) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(input);
    };

    const handleEmailChange = (e) => {
        const input = e.target.value.trim().toLowerCase(); // Trim and convert to lowercase
        setEmail(input);
        setValidEmail(validateEmail(input));
    };

    const loginHandle = async () => {
        setLoading(true); // Start loading
        await LoginRequest(email, password, setIsLoggedIn, navigate);
        setLoading(false); // Stop loading regardless of outcome
    };

    const backgroundImageStyle = {
        backgroundImage: `url("${Image}")`,
        width: '95vw',
        height: '100vh',
        backgroundSize: '100% 100%',
        position: 'absolute'
    };

    return (<>
        <div className="login-body">

            <div className="login-main jquery-ripples-trigger" style={backgroundImageStyle} >
                <div className="animated-text">
                    <TypeScript />
                </div>
                <div className="rotatingText-content">
                    <img className="ecp-logo" src={ecpImg} />

                </div>
                <div style={{ background: 'black', color: 'white', position: 'absolute', bottom: '0px', left: '0px' }}>
                    build : Y24M02D19-RAD-3
                </div>
            </div>
            <div className="login-right">
                <div className="login-right-container">

                    <div className="login-center">
                        <h2>Welcome back!</h2>
                        <p>Please enter your details</p>
                        {/* <div className="serial-input-div">

                            <SerialSelect id="serial-free-select" desc="Vessel's name" />
                        </div> */}
                        <form>
                            <div className="pass-input-div">
                                <input type="text" placeholder="Username" value={email}
                                    onChange={handleEmailChange}
                                    className={!validEmail ? "invalid-input" : ""}
                                />
                            </div>
                            <div className="pass-input-div">
                                <input type={showPassword ? "text" : "password"} onChange={(e) => { setPassword(e.target.value) }} placeholder="Password" />
                                {showPassword ? <FaEyeSlash onClick={() => { setShowPassword(!showPassword) }} /> : <FaEye onClick={() => { setShowPassword(!showPassword) }} />}

                            </div>

                            <div className="login-center-options">
                                <a href="#" className="forgot-pass-link">
                                    Forgot password?
                                </a>
                            </div>
                            <div className="login-center-buttons">
                                <button type="button" onClick={loginHandle}>{!loading ? 'Log In' : <div className="button--loading"></div>}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <img src={ecpIcon} alt="ECP Icon" className="ecp-icon-login" />
                <svg style={{ position: 'fixed', top: '3%', left: '-25%' }} xmlns="http://www.w3.org/2000/svg" id="SvgjsSvg1080" x="0" y="0" version="1.1" viewBox="0 0 511.999 511.999" width="150" height="150" ><path d="M498.523 246.291c37.941-67.923-8.514-149.499-85.88-157.204l-28.232-2.813c-20.59-2.053-38.982-10.974-54.263-28.128-53.185-59.695-152.219-44.954-184.82 26.764-7.94 17.466-22.298 31.438-40.428 39.344l-39.773 17.34c-63.399 27.64-84.894 104.334-44.848 159.183l25.236 34.563c5.481 7.508 9.482 15.807 11.893 24.666l12.532 46.073c17.167 63.115 88.755 97.22 151.241 70.693l5.35-2.271c15.901-6.751 33.992-8.387 50.932-4.616l82.048 18.269c79.933 17.798 150.39-51.574 132.54-127.825l-10.384-44.351c-4.203-17.953-1.524-36.78 7.544-53.012l9.312-16.675z" fill="rgba(0, 37, 65, 1)"></path></svg>
                <svg style={{ position: 'fixed', top: '0%', left: '90%' }} xmlns="http://www.w3.org/2000/svg" id="SvgjsSvg1080" x="0" y="0" version="1.1" viewBox="0 0 511.999 511.999" width="150" height="150" ><path d="M498.523 246.291c37.941-67.923-8.514-149.499-85.88-157.204l-28.232-2.813c-20.59-2.053-38.982-10.974-54.263-28.128-53.185-59.695-152.219-44.954-184.82 26.764-7.94 17.466-22.298 31.438-40.428 39.344l-39.773 17.34c-63.399 27.64-84.894 104.334-44.848 159.183l25.236 34.563c5.481 7.508 9.482 15.807 11.893 24.666l12.532 46.073c17.167 63.115 88.755 97.22 151.241 70.693l5.35-2.271c15.901-6.751 33.992-8.387 50.932-4.616l82.048 18.269c79.933 17.798 150.39-51.574 132.54-127.825l-10.384-44.351c-4.203-17.953-1.524-36.78 7.544-53.012l9.312-16.675z" fill="rgba(0, 37, 65, 1)"></path></svg>
            </div>

            <svg width="100%" style={{ position: 'absolute', bottom: '0%' }} id="SvgjsSvg1080" viewBox="0 0 1440 390" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><path d="M 0,400 L 0,100 C 110,69.21428571428572 220,38.42857142857143 328,53 C 436,67.57142857142857 541.9999999999999,127.5 666,146 C 790.0000000000001,164.5 932,141.57142857142856 1064,127 C 1196,112.42857142857143 1318,106.21428571428572 1440,100 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="#00335b" fill-opacity="0.53" class="transition-all duration-300 ease-in-out delay-150 path-0"></path><path d="M 0,400 L 0,233 C 125.28571428571428,228.92857142857142 250.57142857142856,224.85714285714283 380,241 C 509.42857142857144,257.14285714285717 643,293.5 747,281 C 851,268.5 925.4285714285713,207.14285714285714 1036,191 C 1146.5714285714287,174.85714285714286 1293.2857142857142,203.92857142857144 1440,233 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="#00335b" fill-opacity="1" class="transition-all duration-300 ease-in-out delay-150 path-1"></path></svg>        </div>
    </>
    );

});

export default Login;