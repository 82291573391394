import { clearUserData } from "../../../services/slices/usersSlice"
import { clearCache } from "../../../api/Fetchers/fetch.old.data";

export const  clearData = (dispatch) =>{
    dispatch(clearUserData());
    localStorage.removeItem("apiKey");
    localStorage.removeItem("userID");
    localStorage.removeItem("serials");
    localStorage.removeItem("elements");
    clearCache();

}