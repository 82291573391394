import Configurator from "../../config/Configurator";
import axios from "axios";
import { shipsSerials } from "../../config/serials.config";

export async function fetchSerials() {
    var userID = localStorage.getItem("userID");

    var res = [];

    try {
        let config = {
            method: 'post',
            "timeout": 0,
            url: (await Configurator()).API_URL + '/v2/io/permissions/user/abstract/sources',
            headers: {
                ...axios.defaults.headers.common,
                ...{
                    'Session': localStorage.getItem("apiKey"),
                    'User-Id': userID,
                    "Content-Type": "application/json"
                }
            },
            "data": JSON.stringify({
                "data": {
                    "idUser": parseInt(userID)
                },
                "metadata": {}
            }),
        };
        const response = await axios.request(config);
     
        for (let i = 0; i < response.data.data.length; i++) {
            const serial = response.data.data[i];
            const label = shipsSerials[serial] || serial; // Check if serial exists in shipsSerials, else use serial as label
            res.push({ serial, label });
        }

        console.log(res, 'res2')
        return res;
    } catch (error) {
        console.error("Failed to fetch renamed sensors", error);
        return [];
    }
}