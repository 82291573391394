import { createSlice } from '@reduxjs/toolkit';

const shipsNameSlice = createSlice({
  name: 'shipsName',
  initialState: {},
  reducers: {
    setShipsName: (state, action) => {
      return action.payload;
    },
    clearShipsName: () => {
      return {};
    }
  }
});

export const { setShipsName, clearShipsName } = shipsNameSlice.actions;
export default shipsNameSlice.reducer;
