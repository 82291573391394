import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import './Tooltip.css';

const TooltipModal = ({ className, text, children }) => {
    // Split the text by newline characters to create line breaks
    const formattedText = text.split('\n').map((line, index) => (
        <div key={index}>{line}</div>
    ));

    return (
        <Tooltip
            title={<div style={{ whiteSpace: 'pre-line' }}>{formattedText}</div>}
            arrow
            className={className}
        >
            {children}
        </Tooltip>
    );
};

export default TooltipModal;
