import React, { useRef,lazy,Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from './Layouts/Sidebar/Sidebar';
import Login from './pages/Login';
import InitialLoading from './Components/FallBackScreens/InitialLoading';
import useAppData from './hooks/useAppData';
import useWebSocket from './hooks/useWebSocket';
import { LocationHandler } from './utils/Location/location.handler';
import useSimulationData from './hooks/useSimulationData';
import useAuth from './hooks/useAuth';
import './App.css';
const AppRoutes = lazy(() => import('./config/routes'));

function App() {
  const { isLoggedIn, setIsLoggedIn, loading } = useAuth();
  const { processLiveData } = useAppData(isLoggedIn); 
  const liveFlag = useRef(true);

  const {
    setSimulationPlay,
    simulationData,
    setSimulationData,
    setSliderValue,
    isSliding,
    dataFlag,
    loadingFlag
  } = useSimulationData(processLiveData, liveFlag);

  useWebSocket(isLoggedIn, liveFlag, processLiveData, setIsLoggedIn, isSliding);

   if (process.env.NODE_ENV === "production")
    console.log = function no_console() { };

  return (
    <Router>
      <LocationHandler liveFlag={liveFlag} setSimulationData={setSimulationData} />
      <ToastContainer />
      {!loading && (isLoggedIn ?
        <div className={`App ${window.location.pathname === '/simulator' ? 'simulator-active' : ''}`}>
          <Sidebar setIsLoggedIn={setIsLoggedIn} />
          <Suspense fallback={<InitialLoading />}>
            <AppRoutes
              isSliding={isSliding}
              liveFlag={liveFlag}
              loadingFlag={loadingFlag}
              dataFlag={dataFlag}
              simulationObject={{
                setSimulationData,
                setSimulationPlay,
                setSliderValue,
                simulationData,
                dataFlag,
                loadingFlag,
                liveFlag
              }}
            />
          </Suspense>
        </div>
        : <Login setIsLoggedIn={setIsLoggedIn} />
      )}
    </Router>
  );
}

export default App;
