import Configurator from "../../config/Configurator";
import axios from "axios";

export const fetchTags = async (shipNames) => {
    const userID = localStorage.getItem("userID");

    const apiKey = localStorage.getItem("apiKey");
    var sources = shipNames.map((ship) => {
        return ship?.serial
    });
    console.log("fetchTags", sources);
    try {
        const config = {
            method: 'post',
            url: (await Configurator()).API_URL + '/v2/io/records/abstract/latest/tags',
            headers: {
                ...axios.defaults.headers.common,
                'Api-Key': apiKey,
                'User-Id': userID,
                'Content-Type': 'application/json', 
            },
            data: JSON.stringify({
                data: {
                    sources: sources,
                },
                // metadata: {},
            }),
        };

        const response = await axios.request( config);
        // Check if the response contains the expected data
        if (response.data && response.data.data && response.data.data.tags) {
            return response.data.data.tags;
        } else {
            console.error("Unexpected response format", response.data);
            return {};
        }
    } catch (error) {
        console.error("Failed to fetch tags", error);
        return {}; // Return an empty object in case of error
    }
};