import React, { Children, useEffect, useState } from 'react';
import './Navbar.css';
import ecpIcon from '../../assets/logo192.png';
import mobileIcon from '../../assets/elecicon.webp';
import { useLocation } from 'react-router-dom';
import { navbarIcons } from '../config/navbar.icons';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarVisible, setLargeWindowSidebarVisible } from '../../services/slices/appConfig';
import { isMobile } from 'react-device-detect';
import TooltipModal from '../../Components/Tooltip/TooltipModal';

export default function TopNavBar({ children }) {
    const dispatch = useDispatch();

    const isSidebarVisible = useSelector((state) => state.appConfig.isSidebarVisible);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isLargeWindowSidebarVisible, setIsLargeWindowSidebarVisible] = useState(true);
    const location = useLocation()?.pathname.replaceAll('/', '');

    const navbarClassName = `navbar-v3 ${location === 'configuration' ? 'navbar-config' : ''}`;

    useEffect(() => {
        const handleResize = () => {

            if (isMobile || window.innerWidth < 1100 || window.innerHeight < 500) {
                if (isSidebarVisible) {
                    dispatch(setSidebarVisible(false));
                }
            } else {
                if (!isSidebarVisible) {
                    dispatch(setSidebarVisible(true));
                }
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [isMobile]);

    const handleClickOutside = () => {
        if (isSidebarOpen) {
            hideSidebar();
        }
    };

    const hideSidebar = () => {
        dispatch(setSidebarVisible(false));
        setIsSidebarOpen(false);
    };

    const showSidebar = () => {
        dispatch(setSidebarVisible(true));
        setIsSidebarOpen(true);
    };

    const hideLargeWindowSidebar = () => {
        dispatch(setLargeWindowSidebarVisible(false));
        setIsLargeWindowSidebarVisible(false);
    };

    const showLargeWindowSidebar = () => {
        dispatch(setLargeWindowSidebarVisible(true));
        setIsLargeWindowSidebarVisible(true);
    };



    return (
        <>
            <nav className={navbarClassName}>
                <div className='dashboard-icon' onClick={isSidebarOpen ? hideSidebar : showSidebar}>
                    {isSidebarOpen ? navbarIcons.closeIcon : navbarIcons.dashboardIcon}
                </div>
                <TooltipModal text={`${!isLargeWindowSidebarVisible ? 'Open menu': 'Close menu'}`} className='ecp-tooltip'>
                <div className='ecp-icon-wrapper' onClick={isLargeWindowSidebarVisible ? hideLargeWindowSidebar : showLargeWindowSidebar}>
                  
                    <img className='ecp-icon' src={ecpIcon} alt="ECP Icon" />
                    <svg xmlns="http://www.w3.org/2000/svg" fill='rgba(204, 204, 220, 0.65)' viewBox="0 0 24 24" aria-hidden="true" width="14" height="14" class="css-1d3xu67-Icon"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"></path></svg>
                </div>
                </TooltipModal>

                {children}

            </nav>

            {isSidebarOpen && <div className="sidebar-overlay visible" onClick={handleClickOutside}></div>}
        </>
    );
}