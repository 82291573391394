import React from 'react'
import TopNavBar from "../../Layouts/Navbar/TopNavBar";
import styles from './LoadingScreen.module.css';

const InitialLoading = () => {
    return (
        <>
            <TopNavBar />
                <div className={styles.loadingText}>
                    Loading...
                    <div className={styles.loader}></div>
                </div>
        </>
    )
}

export default InitialLoading