import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMeta } from '../api/Fetchers/fetch.meta';
import { fetchTags } from '../api/Fetchers/fetch.tags';
import { setAlarms, setOperationAlarms } from '../services/slices/amsSlice';
import { setSensorGroups } from '../services/slices/sensorGroupSlice';
import { setRenamedSensors } from '../services/slices/renamedSensorsSlice';
import { setTags } from '../services/slices/tagsSlice';
import { setSensorLimits } from '../services/slices/sensorLimitsSlice';
import { setLiveData } from '../services/slices/liveDataSlice';
import { setShipSerials } from '../services/slices/appConfig';
import { setSensorMultiplier } from '../services/slices/sensorMultiplierSlicer';
import { fetchSerials } from '../api/Fetchers/fetch.serials';

const useAppData = (isLoggedIn) => {
  const dispatch = useDispatch();
  const multiSensors = useSelector((state) => state.sensorMultiplier);
  const currentMulti = useRef({});

  useEffect(() => {
    if (isLoggedIn) {
      currentMulti.current = multiSensors;
    }
  }, [isLoggedIn, multiSensors]);

  const wrapWithSerialIfNeeded = (data, shipSerials) => {

    if (shipSerials.length > 1) { return data; }
    const serial = shipSerials[0]?.serial;

    if (Object.keys(data).length > 0 && !data.hasOwnProperty(serial)) {
      console.log(`Wrapping data with serial ${serial}`);
      return { [serial]: data };
    }
    return data;
  };


  useEffect(() => {
    if (!isLoggedIn) return;

    const fetchData = async () => {
      try {
        const shipNames = await fetchSerials();
        dispatch(setShipSerials(shipNames));

        const [
          groups,
          renamed,
          limits,
          multiplier,
          tags,
          operationAlarms,
        ] = await Promise.all([
          fetchMeta('sensor_groups'),
          fetchMeta('renamed_sensors'),
          fetchMeta('limits'),
          fetchMeta('multiplier'),
          fetchTags(shipNames),
          fetchMeta('alarms'),
        ]);

        // Initialize empty objects for each data type
        const sensorsGroups = groups ? JSON.parse(groups) : {};
        const sensorsRenamed = renamed ? JSON.parse(renamed) : {};
        var sensorsConfig = limits ? JSON.parse(limits) : {};
        if (typeof sensorsConfig === 'string') {
          sensorsConfig = JSON.parse(sensorsConfig);
        }
        const sensorsMultiplier = multiplier ? JSON.parse(JSON.parse(multiplier)) : {};

        const shipSerial = shipNames

        // Wrap each data set with the ship serial if necessary
        const wrappedSensorsGroups = wrapWithSerialIfNeeded(sensorsGroups, shipSerial);
        const wrappedSensorsRenamed = wrapWithSerialIfNeeded(sensorsRenamed, shipSerial);
        const wrappedSensorsConfig = wrapWithSerialIfNeeded(sensorsConfig, shipSerial);
        const wrappedSensorsMultiplier = wrapWithSerialIfNeeded(sensorsMultiplier, shipSerial);


        // Dispatch updated or original data
        dispatch(setSensorGroups(wrappedSensorsGroups));
        dispatch(setRenamedSensors(wrappedSensorsRenamed));
        dispatch(setSensorLimits(wrappedSensorsConfig));
        dispatch(setSensorMultiplier(wrappedSensorsMultiplier));

        const operationAlarmsData = operationAlarms ? JSON.parse(operationAlarms) : {};
        dispatch(setOperationAlarms(operationAlarmsData));
        console.log(tags, 'tags');
        dispatch(setTags(tags));

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        document.querySelector("#index-loading").style.display = "none";
      }
    };

    fetchData();
  }, [isLoggedIn]);


  const processLiveData = (data) => {
    let tags = {
      all: [],
      grouped: {},
      notGrouped: {}
    };

    tags.all = data;

    Object.keys(tags.all).forEach(key => {
      tags.grouped[key] = tags.grouped[key] || {};
      tags.notGrouped = tags.notGrouped || {};

      // SPLIT THEM TO ALARMS AND NOT ALARMS
      tags?.all[key]?.data?.latestRecord?
      Object.keys(tags?.all[key]?.data?.latestRecord).forEach(key2 => {
        if (key2.startsWith("GROUP")) {
          tags.grouped[key][key2] = tags.all[key]?.data?.latestRecord[key2];
        } else {
          tags.notGrouped[key] = tags.notGrouped[key] || { data: { latestRecord: {} } };
          tags.notGrouped[key].data.latestRecord[key2] = currentMulti.current?.[key]?.[key2]
            ? tags.all[key]?.data?.latestRecord[key2] * currentMulti.current[key][key2]
            : tags.all[key]?.data?.latestRecord[key2]
        }
        // console.log( currentMulti.current[key], 'tags.grouped[key][key2]');
      }):
    Object.keys(tags?.all[key]?.data?.record).forEach(key2 => {
        if (key2.startsWith("GROUP")) {
          tags.grouped[key][key2] = tags.all[key]?.data?.record[key2];
        } else {
          tags.notGrouped[key] = tags.notGrouped[key] || { data: { latestRecord: {} } };

          tags.notGrouped[key].data.latestRecord[key2] = currentMulti.current?.[key]?.[key2]
            ? tags.all[key]?.data?.record[key2] * currentMulti.current[key][key2]
            : tags.all[key]?.data?.record[key2]
        }
        // console.log( currentMulti.current[key], 'tags.grouped[key][key2]');
      })
    });
    dispatch(setAlarms(tags.grouped));
    // console.log(tags.all, 'tags.notGrouped');
    dispatch(setLiveData(tags.notGrouped));
  };


  return { processLiveData };
};

export default useAppData;
