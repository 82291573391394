import React from 'react'
import Typical from 'react-typical'

const TypeScript=()=> {
  return (
    <Typical
    steps={[
        'Welcome aboard, Captain!', 1500,
        'Ready to set sail on today\'s journey?', 1500,
        
        'Dive into your boat\'s performance metrics.', 1500,
        'Chart a course through your historical data.', 1500,
        'Smooth sailing with your real-time boat insights.', 1500,
        'Anchor down, review, and compare past voyages.', 1500,
        'Let\'s navigate through your boat\'s health together.', 1500,
        'Seas the day with powerful analytics!', 1500,
        'Welcome back to your digital dock.', 1500,
    ]}
    loop={Infinity}
    wrapper="p"
/>
  )
}


export default React.memo( TypeScript)