
import Configurator from '../../config/Configurator.js';
import notify from '../../utils/Notification/notify.js';

const LoginRequest = async (email, password, setIsLoggedIn, navigate) => {


    if (email !== "" && password !== "") {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: (await Configurator()).API_URL + '/v2/io/users/auth',
            headers: {
                'Api-Key': localStorage.getItem("apiKey"),
                'User-Id': localStorage.getItem("userID"),
            },
            body: JSON.stringify({
                "data": {
                    "name": email,
                    "pass": password
                },
                "metadata": {}
            }),
            timeout: 10000 // Set timeout to 10000 milliseconds (10 seconds)
        };

        try {
            const response = await fetch(config.url, {
                method: config.method,
                headers: config.headers,
                body: config.body,
            });
            const data = await response.json();
            if (data.data.auth) {
                setIsLoggedIn(true);
                localStorage.setItem('userID', data.data.id);
                localStorage.setItem("apiKey", data.data.key);
                window.simulationData = {};
              
                navigate('/dashboard');
            } else {
                notify('Incorrect email or password!');

            }
        } catch (error) {
            if (error.name === 'AbortError') {
              
                notify('Timeout occurred!');
            } else {
                notify('Error during request!')
                console.log(error,'error');
            }
        }
    } else {
        notify('Please fill in all fields!')
    }
};

export default LoginRequest;
